import {
  CustomThemeProvider,
  SkeletonTheme,
} from '@ecatholic/react-components';
import { StrictMode } from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import AppShellRoutes from './appShell/routes/appShell.routes';

render(
  <StrictMode>
    <CustomThemeProvider>
      <SkeletonTheme>
        <BrowserRouter>
          <AppShellRoutes />
        </BrowserRouter>
      </SkeletonTheme>
    </CustomThemeProvider>
  </StrictMode>,
  document.getElementById('root')
);
