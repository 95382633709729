import { Routes, Route, Navigate } from 'react-router-dom';

import LoginPage from '~/authentication/pages/loginPage/LoginPage';
import ConnectPage from '~/connect/pages/connectPage/ConnectPage';
import PaymentsPage from '~/payments/pages/paymentsPage/PaymentsPage';
import Layout from '~/appShell/layout/Layout';
import NotFoundPage from '~/appShell/pages/notFoundPage/NotFoundPage';

import { AppRoutes } from './paths.routes';

const AppShellRoutes = () => {
  return (
    <Routes>
      <Route path={AppRoutes.Login} element={<LoginPage />} />
      <Route path={AppRoutes.Index} element={<Layout />}>
        <Route index element={<Navigate replace to={AppRoutes.Connect} />} />
        <Route path={AppRoutes.Connect} element={<ConnectPage />} />
        <Route path={AppRoutes.Payments} element={<PaymentsPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default AppShellRoutes;
