import { Link, Outlet } from 'react-router-dom';

//TODO install proper navigation
const Layout = () => {
  return (
    <div>
      <nav data-testid="main-navigation">
        <Link to="/connect">Connect</Link> | <Link to="payments">Payments</Link>{' '}
        | <Link to="login">Login</Link>
      </nav>
      <Outlet />
    </div>
  );
};

export default Layout;
